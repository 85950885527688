import React, { Component } from 'react';
import { matchPath, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { apiBuilder } from 'utils/api/common-requests/common-requests';
import * as endpoints from 'utils/api/endpoints';
import { trackedEvents, trackEvent } from 'telemetry/app-analytics';
import {
    isBrandedByHockeyTech,
    isBrandedByMocap,
    isNahlPlayerAccount,
    isProspectMode,
    isSingleTeamView,
    userHavePlayerIdAndFeatureMask
} from 'selectors/user/user-features-selector';
import ThemeContainer from 'components/theme-container/theme-container';
import { clientsAdminIndex } from 'constants/page-names';
import DeviceSelector from 'components/device-selector/device-selector';
import { isUserClient } from 'selectors/user/user-roles-selector';
import { isMobile as isMobileSelector } from 'redux/modules/device-type';
import { LOCATION_LINKS } from 'constants/location-links';
import { readCurrentUser } from 'redux/modules/user';
import { notificationTypes, notify } from 'redux/modules/notifications';

import TooltipAnchor from '../tooltip/anchor';

import ProductSelector from './components/product-selector';
import { LinksNav, Nav } from './components/secondary-navigation-bar';
import {
    clearStoredUserData,
    hasAuthKey,
    hasTemporaryKey,
    isSharedSession,
    joinByOriginKey
} from 'utils/api/auth-utils';
import ErrorBoundary from './private/error-boundary';
import NavBar from './private/components/nav-bar/nav-bar';
import { Brand } from './private/components/nav-bar/nav-bar.styled';
import { BackToAdminLink } from './private/components/back-to-admin-link';
import AppLayout, { AppContent } from './private/layout';
import Language from './private/language';
import Profile from './private/components/profile';
import Footer from './private/footer';
import { logout } from './module.js';
import logoImg from './TPE.svg';
import InfoWarning from './private/info-warning';
import EditEmailModal from './private/edit-email-modal';
import EditPasswordModal from './private/edit-password-modal';
import SupportRequest from './private/components/support-request';
import ClipServiceInformer from './private/components/clip-service-informer';
import NotificationsPanel from './private/notifications-panel';
import TopSearchPanel from './private/components/top-search-panel';
import ReleaseNote from './private/components/release-note/release-note';

const warningForUser = false;

const MAX_WIDTH_PROSPECT = 900;

const mapStateToProps = state => ({
    activeUser: state.user,
    isFavoriteListNeedToFetch: state.favourites.isFavoriteListNeedToFetch,
    singleTeamView: isSingleTeamView(state),
    brandedByMocap: isBrandedByMocap(state),
    brandedByHockeyTech: isBrandedByHockeyTech(state),
    nahlPlayerAccount: isNahlPlayerAccount(state),
    prospectExchangeAccount: isProspectMode(state),
    isUserClient: isUserClient(state),
    isMobile: isMobileSelector(state),
    userHaveSomeFeatureAndPlayerId: userHavePlayerIdAndFeatureMask(state)
});

const mapDispatchToProps = {
    logout,
    readCurrentUser,
    notify
};

class App extends Component {
    state = {
        editEmailIsOpen: false,
        editableEmail: null,
        editPasswordIsOpen: false
    };

    logout() {
        trackEvent(trackedEvents.LOGOUT);

        if (isSharedSession()) {
            clearStoredUserData();
            window.location.assign('/');
            return;
        }

        apiBuilder()
            .post(endpoints.logout)
            .then(() => {
                clearStoredUserData();
                window.location.assign('/');
            })
            .catch(() => window.location.reload());
    }

    editEmail = email => {
        this.setState({ editEmailIsOpen: true, editableEmail: email });
    };

    onCancelEditEmail = () => {
        this.setState({ editEmailIsOpen: false });
    };

    editPassword = () => {
        this.setState({ editPasswordIsOpen: true });
    };

    onCancelEditPassword = () => {
        this.setState({ editPasswordIsOpen: false });
    };

    backToAdmin() {
        apiBuilder()
            .post(endpoints.logout)
            .then(() => {
                joinByOriginKey();
                window.location.assign(`/${clientsAdminIndex.root}/${clientsAdminIndex.users}`);
            })
            .catch(() => window.location.reload());
    }

    isReportPage() {
        const { location } = this.props;
        return (
            matchPath(location.pathname, {
                path: '/report'
            }) ||
            matchPath(location.pathname, {
                path: '/teamNewReport'
            }) ||
            matchPath(location.pathname, {
                path: '/game-reports'
            })
        );
    }

    renderNavBar() {
        const {
            activeUser,
            isFavoriteListNeedToFetch,
            location,
            nahlPlayerAccount,
            prospectExchangeAccount,
            isUserClient,
            isMobile,
            userHaveSomeFeatureAndPlayerId
        } = this.props;
        const { editEmailIsOpen, editableEmail, editPasswordIsOpen } = this.state;

        const showProfile = hasAuthKey() && (!!activeUser && !activeUser.isProcessing);

        const isVisibleTopSearch = isUserClient && !userHaveSomeFeatureAndPlayerId;

        if (prospectExchangeAccount) {
            return (
                <NavBar>
                    {isMobile && (
                        <ProductSelector
                            products={[]}
                            location={location}
                            prospectExchangeAccount={prospectExchangeAccount}
                        />
                    )}
                    <DeviceSelector
                        desktopLayout={
                            <LinksNav location={location} prospectExchangeAccount={prospectExchangeAccount} />
                        }
                    />
                </NavBar>
            );
        }

        return (
            <NavBar>
                <EditEmailModal
                    editEmailIsOpen={editEmailIsOpen}
                    onCancelEditEmail={this.onCancelEditEmail}
                    currentEmail={editableEmail}
                    readCurrentUser={readCurrentUser}
                />

                <EditPasswordModal isOpen={editPasswordIsOpen} onClose={this.onCancelEditPassword} />

                {showProfile && !nahlPlayerAccount ? (
                    <ProductSelector products={LOCATION_LINKS} location={location}>
                        <Brand>{this.renderBrand()}</Brand>
                    </ProductSelector>
                ) : (
                    <Brand>{this.renderBrand()}</Brand>
                )}

                {showProfile && <DeviceSelector desktopLayout={<LinksNav location={location} />} />}

                <Nav align="right">
                    {hasTemporaryKey() && <BackToAdminLink onClick={this.backToAdmin} />}

                    {warningForUser && <InfoWarning />}

                    <ClipServiceInformer />

                    {isVisibleTopSearch && <TopSearchPanel isFavoriteListNeedToFetch={isFavoriteListNeedToFetch} />}

                    <SupportRequest />

                    {showProfile && !isUserClient ? <Language /> : null}

                    {showProfile && <ReleaseNote />}

                    {showProfile && (
                        <Profile
                            user={activeUser}
                            logout={this.logout}
                            onEditEmails={this.editEmail}
                            onEditPassword={this.editPassword}
                        />
                    )}
                </Nav>
            </NavBar>
        );
    }

    renderBrand() {
        const { prospectExchangeAccount } = this.props;

        if (prospectExchangeAccount) {
            return <div />;
        }

        return <img alt="" src={logoImg} height={40} />;
    }

    componentDidMount() {
        const { notify } = this.props;
        const EMAIL_STATUS = localStorage.getItem('UPDATE_EMAIL');

        if (EMAIL_STATUS === 'FAIL') {
            notify(
                'Failed to update your email address. Please log in to the portal and try again to update your email address.',
                notificationTypes.ERROR
            );
            localStorage.setItem('UPDATE_EMAIL', null);
            this.logout();
        }

        if (EMAIL_STATUS === 'SUCCESS') {
            notify('Your email address has been successfully updated.', notificationTypes.SUCCESS);
            localStorage.setItem('UPDATE_EMAIL', null);
            this.logout();
        }
    }

    render() {
        const { children, prospectExchangeAccount } = this.props;
        const showNavBar = !this.isReportPage();
        const showFooter = hasAuthKey() && !this.isReportPage();

        return (
            <ErrorBoundary>
                <ThemeContainer>
                    <AppLayout
                        prospectExchangeAccount={prospectExchangeAccount}
                        maxWidthContainer={prospectExchangeAccount ? MAX_WIDTH_PROSPECT : null}
                    >
                        {showNavBar && this.renderNavBar()}
                        <AppContent>{children}</AppContent>
                        {!prospectExchangeAccount && <Footer />}
                        {showFooter && <TooltipAnchor />}
                        <NotificationsPanel />
                    </AppLayout>
                </ThemeContainer>
            </ErrorBoundary>
        );
    }
}

App = connect(
    mapStateToProps,
    mapDispatchToProps
)(App);

export default withRouter(App);
